(function($) {
  $(document).ready(function() {
    $(document).on('change', 'input[type=file]', function() {
      var fieldVal = $(this).val();
      if (fieldVal) {
        $(this).next('.custom-file-label').text(fieldVal.split('\\').pop());
      } else {
        $(this).next('.custom-file-label').text('Choose file');
      }
    });
  });
})(jQuery);
