(function ($) {
    $(document).ready(function () {
        const search_input = $('#company_search_input')
        if (search_input.length) {
            const first_visible = $('.list-group-item:visible').first()
            if (first_visible) {
                first_visible.addClass('active')
            }
            search_input.on('input', function (event) {
                const first_visible = $('.list-group-item:visible').first()
                if (first_visible) {
                    first_visible.addClass('active')
                }
                const current_input = $(this).val()
                first_visible.removeClass('active')
                $.each($('.list-group-item'), function (index, value) {
                    $(value).text().toLowerCase().includes(current_input.toLowerCase()) ? $(value).show() : $(value).hide()
                });
                const next_first_visible = $('.list-group-item:visible').first()
                if (next_first_visible) {
                    next_first_visible.addClass('active')
                }
            });
            search_input.focus()
            search_input.on('keypress', function (e) {
                const first_visible = $('.list-group-item:visible').first()
                console.log(first_visible)
                if (e.which == 13) {
                    first_visible[0] ? first_visible[0].click() : alert("No companies match your search")
                }
            });
        }
    });
})(jQuery);
