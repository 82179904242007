(function($) {
  $(function() {
    /**
     * Adds submit-on-change behavior to forms marked with a `data-autosubmit`
     * attribute.
     */
    $('form[data-autosubmit]')
        .find('button[type="submit"]').hide().end()
        .change(function() {
          var $form = $(this);
          var replaceSelector = $form.data('autosubmit');

          if (!replaceSelector) {
            return $form.submit();
          }

          $.ajax({
            method: $form.attr('method') || 'GET',
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function(data, statusText, jqXHR) {
              $form.attr('action', jqXHR.getResponseHeader('x-response-url'));
              $(replaceSelector).html($(data).find(replaceSelector).html());
              pygal.init($('.graph svg').get(0));
            },
          });
        });
  });
})(jQuery);
