(function($) {
  $(document).ready(function() {
    $(document).bind('bind.dirtyforms', function(ev, events) {
      var originalOnAnchorClick = events.onAnchorClick;
      var originalOnRefireAnchorClick = events.onRefireAnchorClick;

      var skipAnchorOnlyLink = function(callback) {
        return function(event) {
          var $target = $(event.target).closest('a');
          if ($target.attr('href') && $target.attr('href').startsWith('#')) {
            return;
          }
          callback(event);
        };
      };

      events.onAnchorClick = skipAnchorOnlyLink(originalOnAnchorClick);
      events.onRefireAnchorClick = skipAnchorOnlyLink(originalOnRefireAnchorClick);
    });

    $('form.alert-unsaved-changes').dirtyForms({
      message: 'You have unsaved changes on this page. If you leave, your changes will be lost.',
      dialog: {
        title: 'Leave with unsaved changes?',
        dialogID: 'alert-unsaved-changes-modal',
        titleID: 'alert-unsaved-changes-modal-title',
        messageClass: 'alert-unsaved-changes-modal-message',
        proceedButtonClass: 'alert-unsaved-changes-modal-proceed',
        stayButtonClass: 'alert-unsaved-changes-modal-stay',
      },
    });
  });
})(jQuery);
