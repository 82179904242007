import Sortable from 'sortablejs';

(function($, window, Sortable) {
  window.DynamicFormset = function($container, prefix, options) {
    options = typeof options !== 'undefined' ? options : {};
    this.$container = $container;
    this.prefix = prefix;
    this.options = $.extend({}, {
      'sortable': true,
    }, options);

    this.addRemoveTrigger = function(element) {
      $(element).find('[name$="-DELETE"]')
          .closest('.form-group').hide().end()
          .closest('td')
          .addClass('align-middle')
          .append('<a href="#" class="remove-row text-danger"><i class="fa fa-times"></i></a>');
    };

    this.addSortHandle = function(element) {
      if (!this.options['sortable']) {
        return;
      }
      var $orderTd = $(element).find('[name$="-ORDER"]').closest('td');
      $orderTd
          .closest('tr')
          .prepend('<td class="align-middle"><span class="handle"><i class="fa fa-arrows-alt-v"></i></span></td>');
      var index = $orderTd.index();
      if (index >= 0) {
        this.$container.find('thead th').eq(index).hide();
      }
      $orderTd.hide();
    };

    this.recalculateIndexes = function() {
      this.$container.find('.formset-container .formset-item').each(function(index, element) {
        $(element).find('[name$="-ORDER"]').val(index);
      });
    };

    this.init = function() {
      var that = this;
      var containerId = that.$container.attr('id');

      var $container = this.$container.find('.formset-container');

      // Formset controls.
      $container
          .superformset({
            prefix: this.prefix,
            containerSel: '#' + containerId,
            rowSel: '.formset-item',
            formTemplate: '.empty-form .formset-item',
            addTrigger: '' +
              '<a href="#" class="add-row btn btn-secondary" title="add">' +
                '<i class="fa fa-plus"></i> Add another' +
              '</a>',
            addedCallback: function($element) {
              that.addRemoveTrigger($element);
              that.addSortHandle($element);
              that.recalculateIndexes();
            },
            removedCallback: that.recalculateIndexes,
          })
          .find('.formset-item').each(function(index, element) {
            that.addRemoveTrigger(element);
          }).end()
          .on('click', '.remove-row', function(event) {
            var $element = $(event.target);
            $element.closest('.formset-item')
                .animate({'height': 'toggle', 'opacity': 'toggle'}, 'fast')
                .find('[name$="-DELETE"]').prop('checked', true);
          });

      // Sorting.
      if (this.options['sortable']) {
        $container
            .find('thead tr').prepend('<th>').end()
            .find('.formset-item').each(function(index, element) {
              that.addSortHandle(element);
            });
        Sortable.create($container.find('tbody')[0], {
          handle: '.handle',
          onSort: that.recalculateIndexes.bind(that),
        });
        this.recalculateIndexes();
      }
    };

    this.init();
    return this;
  };
})(jQuery, window, Sortable);
