(function($) {
  $(document).on('click', '.image-with-thumbnails .thumbnails img', function(event) {
    var $element = $(event.target);
    var $container = $element.closest('.image-with-thumbnails');
    $container
        .find('.current').removeClass('current').end()
        .find('.full-image img')
        .attr('src', $element.attr('src'));
    $element.addClass('current');
  });
})(jQuery);
