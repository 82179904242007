(function($) {
  $(document).ready(function() {
    $(document).on('click', '[data-dismissable-alert] .close', function(event) {
      var alert = $(event.target)
          .closest('[data-dismissable-alert]')
          .data('dismissable-alert');
      $.post('/account/alerts/' + alert + '/');
    });
  });
})(jQuery);
