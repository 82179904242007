const liveToast = document.querySelector("#liveToast");

window.showToast = function(title, body) {
    if (!liveToast)
      return;

	$(".toast-header strong", liveToast).html(title);
	$(".toast-body", liveToast).html(body);
	$(liveToast).toast("show");
}
